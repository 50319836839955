import React from "react";
import { InvoiceInterface, OrderInterface } from "../../assets/interfaces";
import { toAppUrl, toCurrency } from "../../assets/js/utils/functions";
import { getWhatsappLink as whatsappLink, resolvePhone } from "../../assets/js/utils/utils";
import { AppBtn } from "../ui/buttons";
import SuccessAnimation from "../ui/success-animation";
import PaymentReference from "./payment-reference";
import getWhatsappLink from "../../assets/js/utils/get-whatsapp-order-link";

interface PaymentSuccessfulProps {
  storeName: string;
  amount: number;
  reference: string;
  storePhone: string;
  orderId: string;
  type: "ORDER" | "INVOICE";
  payFor?: InvoiceInterface["receiver"];
  receipt?: string;
  order?: OrderInterface;
  currency: string;
  chowbotPhone?: string;
}

const PaymentSuccessful: React.FC<PaymentSuccessfulProps> = ({
  storeName,
  storePhone,
  amount,
  reference,
  type,
  orderId,
  payFor,
  receipt,
  order,
  currency,
  chowbotPhone,
}) => {
  const notify = () => {
    const receiptLink = toAppUrl(`receipts/${receipt}`);
    if (chowbotPhone) {
      window.open(whatsappLink(chowbotPhone, ` `));
      return;
    }

    if (order) {
      window
        .open(getWhatsappLink(order, "Hi, I just paid for this order:", storePhone, null, receiptLink), "_blank")
        .focus();
      return;
    }

    if (payFor) {
      window.open(
        whatsappLink(payFor.phone, `Hi, I have completed the payment, here's a link to the receipt: ${receiptLink}`)
      );

      return;
    }

    window.open(
      `https://api.whatsapp.com/send/?phone=${resolvePhone(
        storePhone
      )}&text=Hi, I just made a payment for this ${type.toLocaleLowerCase()} *${orderId}*, here's a link to the receipt: ${receiptLink}`
    );
  };

  return (
    <div className="">
      <div className="flex flex-col items-center pt-4">
        <SuccessAnimation />
        <h1 className="text-xl sm:text-2lg md:text-2xl font-medium text-center mt-5 mb-2.5 !leading-tight">
          <span className="font-bold">{storeName} </span>
          has <br /> received your payment
        </h1>
        <span className="text-sm sm:text-1sm text-center text-dark">
          You’ve successfully paid <br /> <b className="font-semibold">{toCurrency(amount, currency)}</b>
        </span>
        {reference && <PaymentReference reference={reference} />}
        <AppBtn isBlock color="primary" className="hidden lg:block mt-5" size="lg" onClick={notify}>
          {chowbotPhone ? "Back to Chat" : `Notify ${payFor ? payFor.name.split(" ")[0] : "merchant"} on whatsapp`}
        </AppBtn>
      </div>
      <div className="lg:hidden border-t border-grey-border border-opacity-50 py-3.75 lg:py-0 lg:border-none mt-5">
        <AppBtn isBlock color="primary" className="!text-sm" size="lg" onClick={notify}>
          {/* Notify {payFor ? payFor.name.split(" ")[0] : "merchant"} on whatsapp */}
          {chowbotPhone ? "Back to Chat" : `Notify ${payFor ? payFor.name.split(" ")[0] : "merchant"} on whatsapp`}
        </AppBtn>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
