import React from "react";
import { AppBtn } from "../ui/buttons";
import Link from "next/link";
import ErrorIcon from "../../assets/icons/statuses/failed.svg";

interface Props {
  title: string;
  message: string;
}

const ErrorScreen: React.FC<Props> = ({ title, message, children }) => {
  return (
    <div className="h-screen w-full flex flex-col">
      <header className="w-full px-6.25 sm:px-12 md:px-16 lg:px-20 xl:px-25 py-5 sm:py-7.5">
        <div className="w-full flex items-center justify-between">
          <Link href="/">
            <a className="h-5 sm:h-6">
              <img src="/images/logos/primary.svg" alt="" className="h-full" />
            </a>
          </Link>
        </div>
      </header>
      <div className="grid flex-1 w-full place-items-center">
        <div className="flex flex-col items-center">
          <figure className="h-20 w-20 sm:h-25 sm:w-25 rounded-full bg-accent-red-500 text-white mb-5 flex items-center justify-center">
            <ErrorIcon />
          </figure>
          <h4 className="text-lg sm:text-xl lg:text-2lg text-black font-bold mb-0.5">{title}</h4>
          <p className="text-1sm text-dark text-center max-w-[280px]">{message}</p>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
