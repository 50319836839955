import React, { useEffect } from "react";

interface IProps {
  paymentData: {
    reference: string;
    email: string;
    amount: number;
    publicKey: string;
    currency?: string;
  };
  callback: VoidFunction;
  onClose: VoidFunction;
}

const PaystackPayment: React.FC<IProps> = ({ paymentData, onClose, callback }) => {
  const { reference, amount, publicKey, email, currency } = paymentData;

  useEffect(() => {
    const paystackPop = window["PaystackPop"];

    const handler = paystackPop.setup({
      ref: reference,
      email,
      currency,
      amount,
      key: publicKey,
      callback,
      onClose: () => {
        console.log("Paystack widget closed");
      },
    });

    handler.openIframe();
  }, []);

  return null;
};

export default PaystackPayment;
