import React, { useCallback, useEffect, useState } from "react";

interface IProps {
  paymentData: {
    payment_id: string;
    amount: number;
    publicKey: string;
  };
  callback: VoidFunction;
  onClose: VoidFunction;
}

const MonoDirectDebit: React.FC<IProps> = ({ paymentData, onClose, callback }) => {
  const { payment_id, amount, publicKey } = paymentData;

  const payWithMono = useCallback(async () => {
    const MonoConnect = (await import("@mono.co/connect.js")).default;

    const monoInstance = new MonoConnect({
      key: publicKey,
      scope: "payments",
      data: {
        payment_id: payment_id,
      },
      onSuccess: (data) => {
        // console.log("Mono payment successful");
        callback();
      },
    });

    monoInstance.setup();
    monoInstance.open();
  }, []);

  useEffect(() => {
    if (window) {
      payWithMono();
    }
  }, []);

  return null;
};

export default MonoDirectDebit;
