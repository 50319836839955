import React from "react";
import { toAppUrl } from "../../assets/js/utils/functions";
import { resolvePhone } from "../../assets/js/utils/utils";
import { AppBtn } from "../ui/buttons";

interface IProps {
  storeName: string;
  storePhone: string;
  orderId: string;
}

const NoPaymentInformation: React.FC<IProps> = ({ storeName, storePhone, orderId }) => {
  const chatWithSeller = () => {
    window.open(
      `https://api.whatsapp.com/send/?phone=${resolvePhone(
        storePhone
      )}&text=Hi, I'll like to enquire about the status of this order: ${toAppUrl(`orders/${orderId}`)}`
    );
  };

  return (
    <div>
      <div className="w-full flex flex-col items-center">
        <figure className="h-18 w-18 sm:h-20 sm:w-20 lg:h-[90px] lg:w-[90px] rounded-full mb-2.5 sm:mb-4 flex items-center justify-center bg-grey-fields-100 text-grey-muted mt-3.5">
          {/* prettier-ignore */}
          <svg width="50%" viewBox="0 0 24 24" fill="none">
          <path d="M18.9004 15.0298C16.6904 15.0298 14.9004 16.8198 14.9004 19.0298C14.9004 21.2398 16.6904 23.0298 18.9004 23.0298C21.1104 23.0298 22.9004 21.2398 22.9004 19.0298C22.9004 16.8198 21.1104 15.0298 18.9004 15.0298ZM20.5004 20.6798C20.3504 20.8298 20.1603 20.8998 19.9703 20.8998C19.7803 20.8998 19.5904 20.8298 19.4404 20.6798L18.9103 20.1498L18.3604 20.6998C18.2104 20.8498 18.0204 20.9198 17.8304 20.9198C17.6404 20.9198 17.4504 20.8498 17.3004 20.6998C17.0104 20.4098 17.0104 19.9298 17.3004 19.6398L17.8503 19.0898L17.3204 18.5598C17.0304 18.2698 17.0304 17.7898 17.3204 17.4998C17.6104 17.2098 18.0904 17.2098 18.3804 17.4998L18.9103 18.0298L19.4103 17.5298C19.7003 17.2398 20.1803 17.2398 20.4703 17.5298C20.7603 17.8198 20.7603 18.2998 20.4703 18.5898L19.9703 19.0898L20.5004 19.6198C20.7904 19.9098 20.7904 20.3898 20.5004 20.6798Z" fill="currentColor"/>
          <path d="M22 7.5399V8.9999H2V7.5399C2 5.2499 3.86002 3.3999 6.15002 3.3999H17.85C20.14 3.3999 22 5.2499 22 7.5399Z" fill="currentColor"/>
          <path opacity="0.4" d="M2 9V16.46C2 18.75 3.85001 20.6 6.14001 20.6H12.4C12.98 20.6 13.48 20.11 13.43 19.53C13.29 18 13.78 16.34 15.14 15.02C15.7 14.47 16.39 14.05 17.14 13.81C18.39 13.41 19.6 13.46 20.67 13.82C21.32 14.04 22 13.57 22 12.88V9H2ZM8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25Z" fill="currentColor"/>
          <path d="M8.75 16.5C8.75 16.91 8.41 17.25 8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5Z" fill="currentColor"/>
        </svg>
        </figure>
        <h4 className="text-base sm:text-lg lg:text-xl text-black font-bold mb-0.5">No Payment Information</h4>
        <p className="text-xs sm:text-sm text-black-placeholder text-center max-w-[280px]">
          {storeName} has not enabled payments for this invoice
        </p>
        <div className="mt-8 w-full space-y-2.5">
          <AppBtn isBlock color="primary" className="hidden lg:block" size="lg" onClick={chatWithSeller}>
            Chat with merchant
          </AppBtn>
        </div>
      </div>
      <div className="lg:hidden border-t border-grey-border border-opacity-50 mt-8 pt-3.75 lg:py-0 lg:border-none flex items-center">
        <AppBtn isBlock className="flex-1" size="lg" onClick={chatWithSeller}>
          Chat with merchant
        </AppBtn>
      </div>
    </div>
  );
};

export default NoPaymentInformation;
