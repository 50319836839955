import React, { useCallback, useEffect, useRef, useState } from "react";
import ZillaConnect from "@usezilla/zilla-connect";
import { useCheckout, useDirectCharge } from "thepeer-react";

interface IProps {
  paymentData: {
    reference: string;
    amount: number;
    publicKey: string;
    email: string;
    customer: string;
  };
  onSuccess: VoidFunction;
  onClose: VoidFunction;
}

const ThepeerPayment: React.FC<IProps> = ({ paymentData, onClose, onSuccess }) => {
  const { reference, amount, publicKey, email, customer } = paymentData;
  const btnRef = useRef(null);

  const config = {
    publicKey,
    onSuccess,
    amount,
    email,
    currency: "NGN",
    meta: { reference },
    onClose: () => {
      console.log("Thepeer widget closed");
    },
    onError: () => {
      onClose();
    },
  };

  const handleCheckoutPayment = useCheckout({
    ...config,
  });

  useEffect(() => {
    setTimeout(() => {
      btnRef.current.click();
    }, 1000);
  }, []);

  return (
    <>
      <button className="hidden" onClick={handleCheckoutPayment} ref={btnRef}></button>
    </>
  );
};

export default ThepeerPayment;
