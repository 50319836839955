import { GetPaymentMethodsParams, InitiatePaymentParams } from "./interfaces/payments";
import { request } from "./utils";

const InitiatePublicPayment = (data: InitiatePaymentParams) => {
  return request("payments/initiate/public", "post", { data });
};

const GetPaymentMethods = (data: GetPaymentMethodsParams) => {
  return request("payments/public/payment-methods", "post", { data });
};

export { InitiatePublicPayment, GetPaymentMethods };
