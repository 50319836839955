import React from "react";
import { toAppUrl } from "../../assets/js/utils/functions";
import useCopyClipboard from "../hooks/useCopyClipboard";
import Modal, { ModalBody } from "../ui/modal";

interface Props {
  show: boolean;
  toggle: (state: boolean) => void;
  invoiceId: string;
}

enum SHARE_METHODS {
  TWITTER = "TWITTER",
  FACEBOOK = "FACEBOOK",
  WHATSAPP = "WHATSAPP",
  OTHERS = "OTHERS",
}

const ShareWithFriendModal: React.FC<Props> = ({ show, toggle, invoiceId }) => {
  const link = toAppUrl(`pay/${invoiceId}`);
  const [isCopied, copy] = useCopyClipboard(link, { successDuration: 1000 });

  const share = async (method: SHARE_METHODS) => {
    const message = `Use this link to help pay for my order on Catlog: ${link}`;

    switch (method) {
      case SHARE_METHODS.TWITTER:
        window.open(`https://twitter.com/intent/tweet?text=${message}`, "_blank");
        break;
      case SHARE_METHODS.FACEBOOK:
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${link}`, "_blank");
        break;
      case SHARE_METHODS.WHATSAPP:
        window.open(`https://api.whatsapp.com/send?text=${message}`, "_blank");
        break;
      default:
        const shareData = {
          title: "Pay for my order",
          text: "Use this link to help pay for my order on Catlog:",
          url: link,
        };

        if (navigator?.share) {
          await navigator.share(shareData);
        } else {
          alert("Cannot share this link");
        }

        break;
    }
  };

  return (
    <Modal title="Share with friend to pay" {...{ show, toggle }} size="midi">
      <ModalBody>
        <div className="flex flex-col items-center w-full">
          <figure className="h-15 w-15 rounded-full bg-accent-yellow-500 flex items-center justify-center text-white">
            {/* prettier-ignore */}
            <svg width="50%" viewBox="0 0 24 24" fill="none">
              <path d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z" fill="currentColor"/>
              <path d="M14.0809 14.1489C11.2909 12.2889 6.74094 12.2889 3.93094 14.1489C2.66094 14.9989 1.96094 16.1489 1.96094 17.3789C1.96094 18.6089 2.66094 19.7489 3.92094 20.5889C5.32094 21.5289 7.16094 21.9989 9.00094 21.9989C10.8409 21.9989 12.6809 21.5289 14.0809 20.5889C15.3409 19.7389 16.0409 18.5989 16.0409 17.3589C16.0309 16.1289 15.3409 14.9889 14.0809 14.1489Z" fill="currentColor"/>
              <path d="M19.9894 7.33815C20.1494 9.27815 18.7694 10.9781 16.8594 11.2081C16.8494 11.2081 16.8494 11.2081 16.8394 11.2081H16.8094C16.7494 11.2081 16.6894 11.2081 16.6394 11.2281C15.6694 11.2781 14.7794 10.9681 14.1094 10.3981C15.1394 9.47815 15.7294 8.09815 15.6094 6.59815C15.5394 5.78815 15.2594 5.04815 14.8394 4.41815C15.2194 4.22815 15.6594 4.10815 16.1094 4.06815C18.0694 3.89815 19.8194 5.35815 19.9894 7.33815Z" fill="currentColor"/>
              <path d="M21.9883 16.5904C21.9083 17.5604 21.2883 18.4004 20.2483 18.9704C19.2483 19.5204 17.9883 19.7804 16.7383 19.7504C17.4583 19.1004 17.8783 18.2904 17.9583 17.4304C18.0583 16.1904 17.4683 15.0004 16.2883 14.0504C15.6183 13.5204 14.8383 13.1004 13.9883 12.7904C16.1983 12.1504 18.9783 12.5804 20.6883 13.9604C21.6083 14.7004 22.0783 15.6304 21.9883 16.5904Z" fill="currentColor"/>
            </svg>
          </figure>
          <h3 className="text-black font-bold text-2lg sm:text-2xl !leading-tight mt-3 sm:mt-3.5">
            Share with a friend
          </h3>

          <div className="w-full mt-5 max-w-[400px]">
            <div className="flex items-center px-3.5 py-2.5 rounded-30 bg-grey-fields-100 bg-opacity-70 mt-1 relative border border-dashed border-grey-divider">
              <span className="font-semibold text-sm sm:text-1sm text-primary-500">
                {toAppUrl(`pay/${invoiceId}`, false)}
              </span>
              <button
                className="bg-white text-1xs text-black font-semibold flex items-center rounded-30 py-1 px-2 absolute right-3.5 shadow-pill"
                onClick={() => copy()}
              >
                Copy
                {/* prettier-ignore */}
                <svg width="15" viewBox="0 0 24 24" fill="none" className="ml-1">
                  <path d="M13.0601 10.9399C15.3101 13.1899 15.3101 16.8299 13.0601 19.0699C10.8101 21.3099 7.17009 21.3199 4.93009 19.0699C2.69009 16.8199 2.68009 13.1799 4.93009 10.9399" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.59 13.4099C8.24996 11.0699 8.24996 7.26988 10.59 4.91988C12.93 2.56988 16.73 2.57988 19.08 4.91988C21.43 7.25988 21.42 11.0599 19.08 13.4099" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
            <div className="mt-4 border-t border-grey-divider py-4 flex flex-col items-center">
              <span className="text-placeholder text-sm font-medium inline-block">Share Via:</span>
              <div className="flex items-center justify-center space-x-8 w-full mt-3">
                <div className="flex flex-col items-center">
                  <button
                    className="h-10 w-10 sm:h-11.25 sm:w-11.25 rounded-full bg-grey-fields-100 border border-grey-divider flex items-center justify-center text-[#1DA1F2]"
                    onClick={() => share(SHARE_METHODS.TWITTER)}
                  >
                    {/* prettier-ignore */}
                    <svg width="18" viewBox="0 0 15 15" fill="none">
                      <path d="M14.375 1.87506C13.7765 2.29723 13.1138 2.62013 12.4125 2.83131C12.0361 2.3985 11.5358 2.09174 10.9794 1.95251C10.423 1.81328 9.83719 1.84831 9.3013 2.05284C8.76542 2.25738 8.30528 2.62156 7.98311 3.09613C7.66095 3.5707 7.49231 4.13277 7.5 4.70631V5.33131C6.40165 5.35979 5.3133 5.11619 4.33188 4.62221C3.35046 4.12823 2.50645 3.39921 1.875 2.50006C1.875 2.50006 -0.625 8.12506 5 10.6251C3.71283 11.4988 2.17947 11.9369 0.625 11.8751C6.25 15.0001 13.125 11.8751 13.125 4.68756C13.1244 4.51347 13.1077 4.33981 13.075 4.16881C13.7129 3.53974 14.163 2.7455 14.375 1.87506Z" stroke="#F35508" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                  <span className="text-placeholder inline-block mt-1 text-xxs sm:text-xs">Twitter</span>
                </div>
                <div className="flex flex-col items-center">
                  <button
                    className="h-10 w-10 sm:h-11.25 sm:w-11.25 rounded-full bg-grey-fields-100 border border-grey-divider flex items-center justify-center text-[#25D366]"
                    onClick={() => share(SHARE_METHODS.WHATSAPP)}
                  >
                    {/* prettier-ignore */}
                    <svg width="18" viewBox="0 0 24 24" fill="none">
                      <path d="M6.9 20.6C8.4 21.5 10.2 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 13.8 2.5 15.5 3.3 17L2.44044 20.306C2.24572 21.0549 2.93892 21.7317 3.68299 21.5191L6.9 20.6Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16.5 14.8485C16.5 15.0105 16.4639 15.177 16.3873 15.339C16.3107 15.501 16.2116 15.654 16.0809 15.798C15.86 16.041 15.6167 16.2165 15.3418 16.329C15.0714 16.4415 14.7784 16.5 14.4629 16.5C14.0033 16.5 13.512 16.392 12.9937 16.1715C12.4755 15.951 11.9572 15.654 11.4434 15.2805C10.9251 14.9025 10.4339 14.484 9.9652 14.0205C9.501 13.5525 9.08187 13.062 8.70781 12.549C8.33826 12.036 8.04081 11.523 7.82449 11.0145C7.60816 10.5015 7.5 10.011 7.5 9.543C7.5 9.237 7.55408 8.9445 7.66224 8.6745C7.77041 8.4 7.94166 8.148 8.18052 7.923C8.46895 7.6395 8.78443 7.5 9.11793 7.5C9.24412 7.5 9.37031 7.527 9.48297 7.581C9.60015 7.635 9.70381 7.716 9.78493 7.833L10.8305 9.3045C10.9116 9.417 10.9702 9.5205 11.0108 9.6195C11.0513 9.714 11.0739 9.8085 11.0739 9.894C11.0739 10.002 11.0423 10.11 10.9792 10.2135C10.9206 10.317 10.835 10.425 10.7268 10.533L10.3843 10.8885C10.3348 10.938 10.3122 10.9965 10.3122 11.0685C10.3122 11.1045 10.3167 11.136 10.3257 11.172C10.3393 11.208 10.3528 11.235 10.3618 11.262C10.4429 11.4105 10.5826 11.604 10.7809 11.838C10.9837 12.072 11.2 12.3105 11.4344 12.549C11.6778 12.7875 11.9121 13.008 12.151 13.2105C12.3853 13.4085 12.5791 13.5435 12.7323 13.6245C12.7549 13.6335 12.7819 13.647 12.8135 13.6605C12.8495 13.674 12.8856 13.6785 12.9261 13.6785C13.0028 13.6785 13.0613 13.6515 13.1109 13.602L13.4534 13.2645C13.5661 13.152 13.6743 13.0665 13.7779 13.0125C13.8816 12.9495 13.9852 12.918 14.0979 12.918C14.1835 12.918 14.2737 12.936 14.3728 12.9765C14.472 13.017 14.5756 13.0755 14.6883 13.152L16.18 14.2095C16.2972 14.2905 16.3783 14.385 16.4279 14.4975C16.473 14.61 16.5 14.7225 16.5 14.8485Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10"/>
                    </svg>
                  </button>
                  <span className="text-placeholder inline-block mt-1 text-xxs sm:text-xs">Whatsapp</span>
                </div>
                <div className="flex flex-col items-center">
                  <button
                    className="h-10 w-10 sm:h-11.25 sm:w-11.25 rounded-full bg-grey-fields-100 border border-grey-divider flex items-center justify-center text-[#4267B2]"
                    onClick={() => share(SHARE_METHODS.FACEBOOK)}
                  >
                    {/* prettier-ignore */}
                    <svg width="18" viewBox="0 0 24 24" fill="none">
                      <path d="M14 9.3V12.2H16.6C16.8 12.2 16.9 12.4 16.9 12.6L16.5 14.5C16.5 14.6 16.3 14.7 16.2 14.7H14V22H11V14.8H9.3C9.1 14.8 9 14.7 9 14.5V12.6C9 12.4 9.1 12.3 9.3 12.3H11V9C11 7.3 12.3 6 14 6H16.7C16.9 6 17 6.1 17 6.3V8.7C17 8.9 16.9 9 16.7 9H14.3C14.1 9 14 9.1 14 9.3Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                      <path d="M15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                  <span className="text-placeholder inline-block mt-1 text-xxs sm:text-xs">Facebook</span>
                </div>
                <div className="flex flex-col items-center">
                  <button
                    className="h-10 w-10 sm:h-11.25 sm:w-11.25 rounded-full bg-grey-fields-100 border border-grey-divider flex items-center justify-center text-primary-500"
                    onClick={() => share(SHARE_METHODS.OTHERS)}
                  >
                    {/* prettier-ignore */}
                    <svg width="18" viewBox="0 0 24 24" fill="none">
                      <path d="M7.40018 6.31991L15.8902 3.48991C19.7002 2.21991 21.7702 4.29991 20.5102 8.10991L17.6802 16.5999C15.7802 22.3099 12.6602 22.3099 10.7602 16.5999L9.92018 14.0799L7.40018 13.2399C1.69018 11.3399 1.69018 8.22991 7.40018 6.31991Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.1099 13.6501L13.6899 10.0601" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                  <span className="text-placeholder inline-block mt-1 text-xxs sm:text-xs">Others</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShareWithFriendModal;
