import React from "react";

const SuccessAnimation = () => {
  return (
    <figure className="flex items-center justify-center w-[90px] h-[90px] md:w-25 md:h-25 rounded-full bg-success">
      {/* prettier-ignore */}
      <div className="w-11.25 h-11.25 md:h-12.5 md:w-12.5 bg-white rounded-full flex items-center justify-center text-success">
        <svg width="55%" viewBox="0 0 11 8" fill="none" className="mt-[5%]" >
          <path d="M1 3.83L3.83 6.66L9.5 1" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" className="anim-draw-checkmark"/>
        </svg>
      </div>
    </figure>
  );
};

export default SuccessAnimation;
