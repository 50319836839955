import React from "react";
import { AppBtn } from "../ui/buttons";
import ErrorIcon from "../../assets/icons/statuses/error.svg";
import FailedIcon from "../../assets/icons/statuses/failed.svg";
import { toCurrency } from "../../assets/js/utils/functions";
import PaymentReference from "./payment-reference";
import { WHATSAPP_LINK } from "../../assets/js/utils/constants";

interface PaymentFailedProps {
  amount: number;
  reference: string;
  restart: VoidFunction;
  unsure?: boolean;
  currency: string;
}

const PaymentFailed: React.FC<PaymentFailedProps> = ({ reference, amount, restart, unsure, currency }) => {
  const contactSupport = () => {
    window.open(
      `${WHATSAPP_LINK}&text=Hi, I have an issue with this payment. Payment reference ${reference}`,
      "_blank"
    );
  };

  return (
    <div className="">
      <div className=" flex flex-col items-center">
        <figure className="w-[90px] md:w-25 mt-3.5">{unsure ? <FailedIcon /> : <ErrorIcon />}</figure>
        <h1 className="font-bold text-center mt-5 mb-2.5 text-xl sm:text-2lg md:text-2xl">
          {unsure ? "Payment verification failed" : "Your payment failed"}
        </h1>
        {unsure ? (
          <span className="text-sm sm:text-1sm text-center text-dark">
            We couldn&apos;t verify the status of your <br /> payment of{" "}
            <b className="font-semibold">{toCurrency(amount, currency)}</b>
          </span>
        ) : (
          <span className="text-sm sm:text-1sm text-center text-dark">
            We couldn&apos;t process your <br /> payment of{" "}
            <b className="font-semibold">{toCurrency(amount, currency)}</b>
          </span>
        )}
        <PaymentReference reference={reference} />

        <div className=" hidden lg:block w-full border-t border-grey-border border-opacity-50 mt-5 lg:border-none lg:py-0">
          <AppBtn isBlock className="mb-2.5" size="lg" onClick={restart}>
            <span className="mr-1.25">Try Again</span>
            {/* prettier-ignore */}
            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" className="text-white" onClick={contactSupport}>
              <path d="M6.83207 3.80999C7.48457 3.61499 8.20457 3.48749 8.99957 3.48749C12.5921 3.48749 15.5021 6.39749 15.5021 9.98999C15.5021 13.5825 12.5921 16.4925 8.99957 16.4925C5.40707 16.4925 2.49707 13.5825 2.49707 9.98999C2.49707 8.65499 2.90207 7.40999 3.59207 6.37499" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5.90234 3.99L8.06984 1.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5.90234 3.98999L8.42984 5.83499" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </AppBtn>
          <AppBtn isBlock color="neutral" className="!text-sm" size="lg" onClick={contactSupport}>
            <span className="mr-1.25">Contact Support</span>
          </AppBtn>
        </div>
      </div>
      <div className="lg:hidden border-t border-grey-border border-opacity-50 mt-5 py-3.75 lg:py-0 lg:border-none flex items-center">
        <AppBtn isBlock color="neutral" className="!text-sm flex-1 mr-2.5" size="lg" onClick={contactSupport}>
          Contact Support
        </AppBtn>
        <AppBtn isBlock className="flex-1" size="lg" onClick={restart}>
          Try Again
          {/* prettier-ignore */}
          <svg width="16" height="16" viewBox="0 0 18 18" fill="none" className="text-white ml-1.25">
            <path d="M6.83207 3.80999C7.48457 3.61499 8.20457 3.48749 8.99957 3.48749C12.5921 3.48749 15.5021 6.39749 15.5021 9.98999C15.5021 13.5825 12.5921 16.4925 8.99957 16.4925C5.40707 16.4925 2.49707 13.5825 2.49707 9.98999C2.49707 8.65499 2.90207 7.40999 3.59207 6.37499" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.90234 3.99L8.06984 1.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.90234 3.98999L8.42984 5.83499" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </AppBtn>
      </div>
    </div>
  );
};

export default PaymentFailed;
