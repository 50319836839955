import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { copyToClipboard } from "../../assets/js/utils/functions";
import { toast } from "../ui/toast";

interface IOptions {
  /**
   * Reset the status after a certain number of milliseconds. This is useful
   * for showing a temporary success message.
   */
  successDuration?: number;
  successMessage?: string;
  successTitle?: string;
}

export default function useCopyClipboard(text: string, options?: IOptions): [boolean, (text?: string) => void] {
  const [isCopied, setIsCopied] = useState(false);
  const successDuration = options && options.successDuration;
  const [toastOpts, setToastOpts] = useState<any>({
    title: options?.successTitle ?? "Copied to clipboard",
    message: options.successMessage ?? "",
    type: "success",
  });
  // const toastOpts: any = {
  //   title: "Copied to clipboard",
  //   message: "",
  //   type: "success",
  // };

  useEffect(() => {
    if (isCopied && successDuration) {
      toast.success(toastOpts);
      const id = setTimeout(() => {
        setIsCopied(false);
      }, successDuration);

      return () => {
        clearTimeout(id);
      };
    }
  }, [isCopied, successDuration]);

  const copy = (inputText?: string) => {
    const didCopy = copyToClipboard(inputText ?? text);
    setToastOpts({ ...toastOpts, message: options.successMessage ?? inputText ?? text });
    setIsCopied(didCopy);
  };

  return [isCopied, copy];
}
