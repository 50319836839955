import React, { useCallback, useEffect, useRef, useState } from "react";
import ZillaConnect from "@usezilla/zilla-connect";

interface IProps {
  paymentData: {
    reference: string;
    orderCode: string;
    amount: number;
    publicKey: string;
  };
  onSuccess: VoidFunction;
  onClose: VoidFunction;
}

const ZillaPayment: React.FC<IProps> = ({ paymentData, onClose, onSuccess }) => {
  const { reference, amount, publicKey, orderCode } = paymentData;

  useEffect(() => {
    const connect = new ZillaConnect();

    const config = {
      publicKey,
      onSuccess,
      orderCode,
      onClose: () => {
        console.log("Zilla widget closed");
      },
      onEvent: (eventName) => {
        if (eventName === "ERROR") {
          onClose();
          return;
        }
      },
    };

    connect.openById(config);
  }, []);

  return null;
};

export default ZillaPayment;
