import Toast from "react-hot-toast";

export interface NotificationProps {
  id?: string;
  title: string;
  message: string;
  type?: "loading" | "success" | "error";
  actionText?: string;
  actionFunc?: () => void;
  duration?: number;
}

const Notification: React.FC<NotificationProps> = (props) => {
  const { title, message, type, actionFunc, actionText, id } = props;
  const isSuccess = type === "success";
  const isError = type === "error";
  const isLoading = type === "loading";

  return (
    <div className="max-w-lg w-96 bg-black shadow-lg rounded-10 pointer-events-auto flex items-stretch overflow-hidden">
      <div className="border-r border-placeholder border-opacity-70 h-full py-3.5 px-5 flex items-center justify-center flex-shrink-0">
        {isSuccess && (
          <svg width="30" height="30" viewBox="0 0 20 20" fill="none" className="text-success">
            <rect width="20" height="20" rx="10" fill="currentColor" />
            <path
              d="M14 7.6665L8.5 13.1665L6 10.6665"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}

        {isError && (
          <svg width="30" height="30" viewBox="0 0 20 20" fill="none" className="text-accent-red-500">
            <rect width="20" height="20" rx="10" fill="currentColor" />
            <path d="M13 7L7 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 7L13 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        )}

        {isLoading && (
          <div className="text-white">
            <div className="spinner"></div>
          </div>
        )}
      </div>
      <div className="flex-1 overflow-hidden">
        <div className="p-4 w-full">
          <h6 className="text-white font-bold text-sm">{title}</h6>
          <p className="mt-1 text-sm text-grey-border w-full max-w-full overflow-hidden overflow-ellipsis">{message}</p>
        </div>
        <div className="flex border-t border-placeholder border-opacity-70 w-full items-stretch">
          {actionFunc && actionText && (
            <button
              onClick={actionFunc}
              className="flex-1 h-full flex items-center text-sm font-medium font-action text-white justify-center text-center bg-white bg-opacity-0 hover:bg-opacity-5 border-r border-placeholder border-opacity-70 p-3"
            >
              {actionText}
            </button>
          )}
          <button
            onClick={() => Toast.dismiss(id)}
            className="flex-1 h-full flex items-center text-sm font-medium font-action text-white justify-center text-center bg-white bg-opacity-0 hover:bg-opacity-5 p-3"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notification;
