import React, { useEffect, VoidFunctionComponent } from "react";

interface IProps {
  triggerError: VoidFunction;
  handleSuccess: VoidFunction;
  paymentSuccessful: boolean;
}

const PaymentAwaitingConfirmation: React.FC<IProps> = ({ triggerError, paymentSuccessful, handleSuccess }) => {
  useEffect(() => {
    //DOING THIS TO COUNTER CASES WHERE WEBSOCKT IS CALLED BEFORE PAYMENT WIDGET IS CLOSED
    if (paymentSuccessful) {
      handleSuccess();
      return;
    }

    const interval = setTimeout(() => {
      triggerError();
    }, 3 * 60 * 1000);

    return () => clearTimeout(interval);
  }, []);

  return (
    <div className="flex flex-col items-center py-30">
      <figure className="text-primary-500">
        <div className="spinner spinner--lg"></div>
      </figure>
      <span className="text-1sm sm:text-base font-medium text-placeholder mt-3.75 text-center">
        Awaiting payment <br /> confirmation
      </span>
    </div>
  );
};

export default PaymentAwaitingConfirmation;
