import React from "react";
import { InvoiceInterface } from "../../assets/interfaces";
import { toAppUrl } from "../../assets/js/utils/functions";
import { resolvePhone } from "../../assets/js/utils/utils";
import { AppBtn } from "../ui/buttons";

interface IProps {
  storePhone: string;
  orderId: string;
  type?: "ORDER" | "INVOICE";
  payFor?: InvoiceInterface["receiver"];
  receipt?: string;
}

const PaymentMade: React.FC<IProps> = ({ storePhone, orderId, type, payFor, receipt }) => {
  const chatWithSeller = () => {
    const link =
      type === "ORDER"
        ? `https://api.whatsapp.com/send/?phone=${resolvePhone(
            storePhone
          )}&text=Hi, I'll like to enquire about the status of this order: ${toAppUrl(`orders/${orderId}`)}`
        : `https://api.whatsapp.com/send/?phone=${resolvePhone(storePhone)}`;

    window.open(link, "_blank");
  };

  const closePage = () => window.close();

  return (
    <div className="">
      <div className="flex flex-col items-center pt-4">
        <figure className="flex items-center justify-center w-15 h-15 md:h-17.5 md:w-17.5 rounded-full bg-success">
          {/* prettier-ignore */}
          <svg width="50%" viewBox="0 0 30 30" fill="none">
            <path d="M23.75 18.75C20.9875 18.75 18.75 20.9875 18.75 23.75C18.75 24.6875 19.0125 25.575 19.475 26.325C20.3375 27.775 21.925 28.75 23.75 28.75C25.575 28.75 27.1625 27.775 28.025 26.325C28.4875 25.575 28.75 24.6875 28.75 23.75C28.75 20.9875 26.5125 18.75 23.75 18.75ZM26.3375 23.2125L23.675 25.675C23.5 25.8375 23.2625 25.925 23.0375 25.925C22.8 25.925 22.5625 25.8375 22.375 25.65L21.1375 24.4125C20.775 24.05 20.775 23.45 21.1375 23.0875C21.5 22.725 22.1 22.725 22.4625 23.0875L23.0625 23.6875L25.0625 21.8375C25.4375 21.4875 26.0375 21.5125 26.3875 21.8875C26.7375 22.2625 26.7125 22.85 26.3375 23.2125Z" fill="white" />
            <path d="M27.5 9.4375V10C27.5 10.6875 26.9375 11.25 26.25 11.25H3.75C3.0625 11.25 2.5 10.6875 2.5 10V9.425C2.5 6.5625 4.8125 4.25 7.675 4.25H22.3125C25.175 4.25 27.5 6.575 27.5 9.4375Z" fill="white" />
            <path d="M2.5 14.375V20.575C2.5 23.4375 4.8125 25.75 7.675 25.75H15.5C16.225 25.75 16.85 25.1375 16.7875 24.4125C16.6125 22.5 17.225 20.425 18.925 18.775C19.625 18.0875 20.4875 17.5625 21.425 17.2625C22.9875 16.7625 24.5 16.825 25.8375 17.275C26.65 17.55 27.5 16.9625 27.5 16.1V14.3625C27.5 13.675 26.9375 13.1125 26.25 13.1125H3.75C3.0625 13.125 2.5 13.6875 2.5 14.375ZM10 21.5625H7.5C6.9875 21.5625 6.5625 21.1375 6.5625 20.625C6.5625 20.1125 6.9875 19.6875 7.5 19.6875H10C10.5125 19.6875 10.9375 20.1125 10.9375 20.625C10.9375 21.1375 10.5125 21.5625 10 21.5625Z" fill="white" />
          </svg>
        </figure>
        <h1 className="text-xl sm:text-2lg md:text-2xl font-medium text-center mt-5 mb-2.5 !leading-tight">
          {payFor ? (
            <>
              This payment <br /> <b className="font-bold">has been completed!</b>
            </>
          ) : (
            <>
              You&apos;ve paid <br /> <b className="font-bold">for this {type.toLocaleLowerCase()}</b>
            </>
          )}
        </h1>
        <div className="mt-5 w-full space-y-2.5">
          {/* Not showing receipt link, because invoice page is public */}
          {type === "ORDER" && (
            <AppBtn
              isBlock
              color="neutral"
              className="!text-sm hidden lg:flex"
              size="lg"
              href={toAppUrl(`receipts/${receipt}`)}
            >
              View Receipt
            </AppBtn>
          )}
          <AppBtn
            isBlock
            color="primary"
            className="hidden lg:block"
            size="lg"
            onClick={payFor ? closePage : chatWithSeller}
          >
            {payFor ? "Close Page" : "Chat with merchant"}
          </AppBtn>
        </div>
      </div>
      <div className="lg:hidden border-t border-grey-border border-opacity-50 mt-5 py-3.75 lg:py-0 lg:border-none flex-col space-y-2.5">
        <div className="w-full">
          {type === "ORDER" && (
            <AppBtn
              isBlock
              color="neutral"
              className="!text-sm flex-1 mr-2.5"
              size="lg"
              href={toAppUrl(`receipts/${receipt}`)}
            >
              View Receipt
            </AppBtn>
          )}
        </div>
        <div className="w-full">
          <AppBtn isBlock className="flex-1" size="lg" onClick={payFor ? closePage : chatWithSeller}>
            {payFor ? "Close Page" : "Chat with merchant"}
          </AppBtn>
        </div>
      </div>
    </div>
  );
};

export default PaymentMade;
