import React from "react";
import useCopyClipboard from "../hooks/useCopyClipboard";

interface PaymentReferenceInterface {
  reference: string;
}

const PaymentReference: React.FC<PaymentReferenceInterface> = ({ reference }) => {
  const [copied, copy] = useCopyClipboard(reference, {
    successDuration: 500,
  });

  return (
    <div className="flex flex-col items-center w-full bg-grey-fields-200 rounded-10 overflow-hidden py-7.5 mt-6.25 lg:py-6.25">
      <span className="bg-white text-xs font-semibold rounded-5 px-2.5 py-1.5 text-primary-500">Payment Reference</span>
      <button className="flex items-center mt-2.5 no-outline" onClick={() => copy()}>
        <h1 className="text-lg font-bold">{copied ? "Copied!" : reference}</h1>
        <span className="flex items-center justify-center text-primary-500 bg-primary-500 bg-opacity-5 rounded-5 p-1.25 ml-1.25">
          {/* prettier-ignore */}
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
            <path d="M6.47533 13.7709H4.02533C1.74449 13.7709 0.729492 12.7559 0.729492 10.475V8.02502C0.729492 5.74419 1.74449 4.72919 4.02533 4.72919H6.47533C8.75616 4.72919 9.77116 5.74419 9.77116 8.02502V10.475C9.77116 12.7559 8.75616 13.7709 6.47533 13.7709ZM4.02533 5.60419C2.21699 5.60419 1.60449 6.21669 1.60449 8.02502V10.475C1.60449 12.2834 2.21699 12.8959 4.02533 12.8959H6.47533C8.28366 12.8959 8.89616 12.2834 8.89616 10.475V8.02502C8.89616 6.21669 8.28366 5.60419 6.47533 5.60419H4.02533Z" fill="currentColor" />
            <path d="M9.97533 10.2709H9.33366C9.09449 10.2709 8.89616 10.0725 8.89616 9.83335V8.02502C8.89616 6.21669 8.28366 5.60419 6.47533 5.60419H4.66699C4.42783 5.60419 4.22949 5.40585 4.22949 5.16669V4.52502C4.22949 2.24419 5.24449 1.22919 7.52533 1.22919H9.97533C12.2562 1.22919 13.2712 2.24419 13.2712 4.52502V6.97502C13.2712 9.25585 12.2562 10.2709 9.97533 10.2709ZM9.77116 9.39585H9.97533C11.7837 9.39585 12.3962 8.78335 12.3962 6.97502V4.52502C12.3962 2.71669 11.7837 2.10419 9.97533 2.10419H7.52533C5.71699 2.10419 5.10449 2.71669 5.10449 4.52502V4.72919H6.47533C8.75616 4.72919 9.77116 5.74419 9.77116 8.02502V9.39585Z" fill="currentColor" />
          </svg>
        </span>
      </button>
    </div>
  );
};

export default PaymentReference;
