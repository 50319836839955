import { useEffect, useState } from "react";

const useSteps = (steps: string[], active: number) => {
  const [step, setStep] = useState(steps[active]);
  const [lastStep, setLastStep] = useState<string>(null);
  const canNext = steps.indexOf(step) < steps.length - 1;
  const canPrevious = steps.indexOf(step) > 0;

  const isActive = (s: string) => {
    return step === s;
  };

  const handleNext = () => {
    if (step) {
      setLastStep(step);
    }

    if (canNext) {
      const index = steps.indexOf(step);
      setStep(steps[index + 1]);
    }
  };

  const handlePrevious = () => {
    if (lastStep) {
      setStep(lastStep);
      setLastStep(undefined);
      return;
    }

    if (canPrevious) {
      const index = steps.indexOf(step);
      setStep(steps[index - 1]);
    }
  };

  const changeStep = (newStep: string) => {
    if (step) {
      setLastStep(step);
    }

    setStep(newStep);
  };

  return {
    step,
    changeStep,
    stepIndex: steps.indexOf(step),
    isActive,
    steps,
    next: handleNext,
    previous: handlePrevious,
    canNext,
    canPrevious,
  };
};

export default useSteps;
